/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { event as gaEvent } from 'react-ga';
import Svg from 'components/Svg';
import Dropdown from 'images/dropdown.svg';
import { useHistory } from 'react-router-dom';
import API from 'helpers/API';
import ReactTooltip from 'react-tooltip';
import { reactLocalStorage } from 'reactjs-localstorage';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import { logout as logoutAction } from 'actions';
import LayoutContext from '../Layout/LayoutContext';
import styles from './styles.scss';

const ENTER_CHAR_CODE = 13;
const SEARCH_FILTER = 'SearchFilter';
const DEFAULT_SEARCH_FILTERS = [
  { value: 'trackingReference', name: 'Tracking number' },
  { value: 'customReference', name: 'Custom reference' },
  { value: 'packageTrackingReference', name: 'Packing number' },
  { value: 'originPostalCode', name: 'Origin postal code' },
  { value: 'destinationPostalCode', name: 'Destination postal code' },
  { value: 'emailAddress', name: 'Recipient email' },
];

const stylesMUI = {
  menuWrapper: {
    padding: 0,
    cursor: 'pointer',
  },
  menu: {
    top: '30px',
  },
  menuItem: {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  iconAditionalsSyles: {
    marginTop: '6px',
  },
};

export function CSearchBar({ classes }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const [isSearchFilterOpen, setIsSearchFilterOpen] = useState(false);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [hoveredFilterIndex, setHoveredFilterIndex] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [searchFilters, setSearchFilters] = useState(DEFAULT_SEARCH_FILTERS);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const history = useHistory();
  const { shipmentMetadataGroupings } = useContext(LayoutContext);
  const wrapperRef = useRef(null);
  const selectedFilterRef = useRef(null);
  const [openLoginMenu, setOpenLoginMenu] = useState(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  const handleIconClick = (event) => {
    setOpenLoginMenu(true);
  };

  const handleMenuClose = () => {
    setOpenLoginMenu(false);
  };

  const StyledMenu = withStyles({
    paper: {
      width: '150px',
    },
  })(Menu);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontFamily: 'RundText, Arial, sans-serif',
      fontSize: 16,
    },
  }))(Tooltip);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsSearchFilterOpen(false);
        setHoveredFilterIndex(selectedFilterIndex);
      }
    };
    document.addEventListener('click', handleClickOutside, false);

    const isTextClipped = selectedFilterRef.current.offsetWidth < selectedFilterRef.current.scrollWidth;
    setIsTooltipVisible(isTextClipped);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [selectedFilterIndex]);

  useEffect(() => {
    const metadataGroups = shipmentMetadataGroupings.map(x => (
      {
        name: x.displayName,
        value: x.metadataKey,
        isMetadata: true,
      }));
    const filters = [
      ...DEFAULT_SEARCH_FILTERS,
      ...metadataGroups,
    ];
    setSearchFilters(filters);

    const search = reactLocalStorage.get(SEARCH_FILTER);
    const index = filters.findIndex(x => x.value === search);
    const updatedSelectedFilterIndex = index > 0 ? index : 0;
    setSelectedFilterIndex(updatedSelectedFilterIndex);
  }, [shipmentMetadataGroupings]);

  async function performSearch() {
    const params = new URLSearchParams();
    const selected = searchFilters[selectedFilterIndex];

    let searchQueryKey = selected.value;
    if (selected.isMetadata) {
      params.set('metadataKey', selected.value);
      searchQueryKey = 'metadataValue';
    }
    params.set(searchQueryKey, searchQuery);

    const response = await API.getShipments(params.toString());

    let url = '/shipments';
    if (response.shipments.length === 1 || (selected.isMetadata && response.shipments.length)) {
      url = `${url}/${response.shipments[0].id}`;
      if (selected.isMetadata) {
        url = `${url}?${params.toString()}`;
      }
    } else if (params.toString()) {
      url = `${url}?${params.toString()}`;
    }
    history.push(url);
  }

  async function handleKeyPress(event) {
    if (event.charCode === ENTER_CHAR_CODE) {
      await performSearch();
    }
  }

  async function handleSearchClick() {
    await performSearch();
  }

  function handleChange({ target: { value } }) {
    gaEvent({
      category: 'Navigation',
      action: 'Search Bar Typing',
    });
    setSearchQuery(value);
    setIsNotFound(false);
  }

  function handleClick() {
    gaEvent({
      category: 'Navigation',
      action: 'Search Bar',
    });
  }

  function handleFilterClicked(i) {
    setSelectedFilter(searchFilters[i]);
    setSelectedFilterIndex(i);
    reactLocalStorage.set(SEARCH_FILTER, searchFilters[i].value);
  }

  function handleSearchFilters() {
    setIsSearchFilterOpen(x => !x);
    setHoveredFilterIndex(selectedFilterIndex);
  }

  function handleOnHover(i) {
    setHoveredFilterIndex(i);
  }

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.innerSearchBlock}>
        <div role="button" className={styles.dropdown} onClick={handleSearchFilters} ref={wrapperRef}>
          <div className={styles.dropdownButton}>
            <span data-tip={searchFilters[selectedFilterIndex].name} data-for="search-bar" className={styles.dropdownValue} ref={selectedFilterRef}>
              {searchFilters[selectedFilterIndex].name}
            </span>
            <Dropdown className={`${styles.drowdownIcon} ${isSearchFilterOpen && styles.dropDownIconOpen}`} />
          </div>
          {isTooltipVisible && <ReactTooltip type="dark" effect="float" id="search-bar" />}
          {isSearchFilterOpen && (
            <ul className={styles.searchFilters}>
              {searchFilters.map((filter, i) => (
                <li
                  role="presentation"
                  key={filter.value}
                  className={classNames(styles.searchFilterItem, {
                    [styles.selectedFilter]: selectedFilter === filter,
                  })}
                  onClick={() => handleFilterClicked(i)}
                  onMouseEnter={() => handleOnHover(i)}
                  style={{ backgroundColor: i === hoveredFilterIndex ? '#f1f1f1' : 'white' }}
                >
                  <span className={styles.filterName}>{filter.name}</span>
                  {selectedFilter === filter && (
                    <Svg name="checkmarkDropdown" className={styles.iconSVG} />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={styles.inputContainer}>
          <input
            className={styles.inputStyles}
            type="search"
            placeholder="Search"
            value={searchQuery}
            onClick={handleClick}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            title="Shipments can be searched by tracking number, custom reference, package reference, postal codes, email or shipment grouping"
          />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className={styles.iconSearchContainer} onClick={() => handleSearchClick()}>
            <Svg name="searchGlass" className={classNames(styles.icon, styles.iconSearch)} />
          </div>
        </div>
        {isNotFound && (<div>Shipment not found</div>)}
      </div>
      <div className={styles.innerInfoBlock}>
        <a href="https://docs.sorted.com/react/" target="_blank" rel="noreferrer">
          <LightTooltip disableFocusListener disableTouchListener title="Help opens in a new window">
            <Svg name="circleQuestion" className={styles.icon} />
          </LightTooltip>
        </a>
        <div className={classes.menuWrapper}>
          <StyledMenu
            anchorEl={document.getElementById('svg-icon')}
            open={openLoginMenu}
            onClose={handleMenuClose}
            className={classes.menu}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={handleLogout}
            >
              <Svg name="logOutIcon" className={classNames(styles.icon, classes.iconAditionalsSyles)} />
              <div>Logout</div>
            </MenuItem>
          </StyledMenu>
          <Svg
            id="svg-icon"
            name="circleUser"
            className={styles.icon}
            onClick={handleIconClick}
          />
        </div>
      </div>
    </div>
  );
}

export default withStyles(stylesMUI)(CSearchBar);
